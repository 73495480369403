import PropTypes from "prop-types";
import React from "react";
import AccountItemBody from "./account-item-body";
import AccountItemContainer from "./account-item-container";
import AccountItemHeader from "./account-item-header";
import EditableTextField from "./editable-text-field";
import UserPhoneNumberInput from "./phone-number-input";

interface UserData {
  email: string;
  phoneNumber: string;
}

const ContactInfoComponent = ({ userData }: UserData) => {
  const { email = "" } = userData;

  return (
    <AccountItemContainer>
      <AccountItemHeader title="Contact Info" />
      <AccountItemBody title="Email Address " element={email} />
      <AccountItemBody
        title="Phone Number"
        element={<UserPhoneNumberInput value={userData.phoneNumber} />}
      />
      <AccountItemBody
        title="Postal Address"
        element={<EditableTextField value={userData} name="postalAddress" />}
      />
    </AccountItemContainer>
  );
};

ContactInfoComponent.propTypes = {
  userData: PropTypes.object,
};

export default ContactInfoComponent;
