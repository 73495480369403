import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/min";
import React, { useEffect, useMemo, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useUpdateUser } from "../../../hooks/user.hooks.ts";

const FOCUS_TIMEOUT = 50;

const formatPhoneNumberWithZeroPrefix = (phone) => {
  if (phone.startsWith("0")) {
    return phone;
  }
  return `0${phone}`;
};

const PhoneNumberInput = ({
  inputRef,
  phoneNumber,
  countryCode,
  handleSubmitPhoneNumber = () => {},
  handleChangePhoneNumber = () => {},
}) => {
  return (
    <PhoneInput
      key={countryCode || phoneNumber}
      ref={inputRef}
      value={formatPhoneNumberWithZeroPrefix(phoneNumber)}
      placeholder="Input your phone number"
      masks={{
        au: ".... ... ... ....",
        nz: ".... ... ... ....",
      }}
      enableSearch={false}
      onlyCountries={["au", "nz", "vn"]}
      disableCountryCode
      country={countryCode}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          handleSubmitPhoneNumber();
        }
      }}
      onBlur={handleSubmitPhoneNumber}
      onChange={(value, country, e, formattedValue) => {
        handleChangePhoneNumber({
          name: "phoneNumber",
          value,
          country: country.countryCode,
          dialCode: country.dialCode,
          formattedValue,
        });
        setTimeout(() => {
          inputRef.current.numberInputRef.focus();
        }, FOCUS_TIMEOUT);
      }}
    />
  );
};

const UserPhoneNumberInput = ({ value: phoneNumberValue = "" }) => {
  const inputRef = useRef();
  const [userPhoneNumberDetails, setUserPhoneNumberDetails] = useState({});
  const [fieldError, setFieldError] = useState("");
  const updateUser = useUpdateUser();
  const handleInputPhoneNumber = (phoneNumberParams) => {
    setFieldError("");
    const { value, country, dialCode } = phoneNumberParams;
    setUserPhoneNumberDetails({
      ...userPhoneNumberDetails,
      country,
      nationalNumber: value,
      countryCallingCode: dialCode,
      dialCode,
    });
  };
  useEffect(() => {
    const isValid = isValidPhoneNumber(phoneNumberValue);
    if (isValid) {
      setUserPhoneNumberDetails(parsePhoneNumber(phoneNumberValue));
    } else {
      setUserPhoneNumberDetails({ nationalNumber: phoneNumberValue });
    }
  }, [phoneNumberValue]);
  const phoneNumberWithCountryCode = useMemo(() => {
    return `+${userPhoneNumberDetails.countryCallingCode}${userPhoneNumberDetails.nationalNumber}`;
  }, [userPhoneNumberDetails]);
  const handleClickButton = () => {
    const isValid = parsePhoneNumber(phoneNumberWithCountryCode).isValid();
    if (isValid) {
      const phoneNumberParams = {
        phoneNumber: phoneNumberWithCountryCode,
      };
      setFieldError("");
      updateUser.mutate(phoneNumberParams);
    } else {
      setFieldError("Invalid Phone Number");
    }
  };

  return (
    <div>
      <PhoneNumberInput
        inputRef={inputRef}
        phoneNumber={`${userPhoneNumberDetails.nationalNumber}`}
        handleSubmitPhoneNumber={handleClickButton}
        handleChangePhoneNumber={handleInputPhoneNumber}
        countryCode={userPhoneNumberDetails?.country?.toLowerCase() || "au"}
      />
      <div className="text-danger">{fieldError}</div>
    </div>
  );
};

UserPhoneNumberInput.propTypes = {};

export default UserPhoneNumberInput;
