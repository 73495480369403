import PropTypes from "prop-types";
import React from "react";
import AccountItemContainer from "./account-item-container";
import AccountItemBody from "./account-item-body";
import AccountItemHeader from "./account-item-header";
import ChangePasswordSection from "./change-password-section";
import EditableTextField from "./editable-text-field";

const ProfileComponent = ({ userData = {} }) => {
  return (
    <AccountItemContainer>
      <AccountItemHeader
        title="Profile"
        description="This is used to help us provide you with more precision information."
      />
      <AccountItemBody
        title="Name"
        element={<EditableTextField value={userData} name="fullName" />}
      />
      <AccountItemBody
        title="DOB"
        element={<EditableTextField value={userData} name="birthday" />}
      />
      <AccountItemBody
        title="Gender"
        element={<EditableTextField value={userData} name="gender" />}
      />
      <AccountItemBody title="Password" element={<ChangePasswordSection />} />
    </AccountItemContainer>
  );
};

ProfileComponent.propTypes = {
  userData: PropTypes.object,
};

export default ProfileComponent;
