import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React from "react";
import { CustomButtonOutLine } from "../../../components/custom-button";
import { useDeleteAccount } from "../../../hooks/user.hooks.ts";
import { logout } from "../../../utils/auth";
import AccountItemContainer from "./account-item-container";
import AccountItemHeader from "./account-item-header";

const DeleteAccountComponent = () => {
  const deleteMeValidStr = "delete me";
  const [open, setOpen] = React.useState(false);
  const [isAllowUserDelete, setIsAllowUserDelete] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleInputChange = (e) => {
    if (e.target.value === deleteMeValidStr) {
      setIsAllowUserDelete(true);
    } else {
      setIsAllowUserDelete(false);
    }
  };

  const handleClose = () => {
    setIsAllowUserDelete(false);
    setOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const deleteAccount = useDeleteAccount({
    onSuccess: () => {
      enqueueSnackbar("Delete successfully", { variant: "Success" });
      logout();
    },
  });

  const handleDeleteAccount = () => {
    deleteAccount.mutate();
  };
  return (
    <AccountItemContainer>
      <AccountItemHeader title="Account Setting" />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete your account?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete your account?
          </DialogContentText>
          <div>
            To confirm deletion, type <i>{deleteMeValidStr}</i> into the field.
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            // label= {`To confirm deletion, type ${deleteMeValidStr} into the field.`}
            fullWidth
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAccount}
            disabled={!isAllowUserDelete}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ padding: "1rem" }}>
        <CustomButtonOutLine label="Delete Account" onClick={handleClickOpen} />
      </div>
    </AccountItemContainer>
  );
};

export default DeleteAccountComponent;
