import Switch from "@material-ui/core/Switch";
import React from "react";
import { UpdateUserInfo } from "../../../types/user.types";
import { useMyInfo, useUpdateUser } from "../../../hooks/user.hooks";
import AccountItemContainer from "../personal-account/account-item-container";
import AccountItemHeader from "../personal-account/account-item-header";
import "./notification-setting.scss";

interface NotificationItemProps {
  checked: boolean;
  reportType: string;
  handleChange: any;
  title: string;
  disabled: boolean;
}

const NotificationItem: React.FunctionComponent<NotificationItemProps> = ({
  checked,
  reportType,
  handleChange,
  title,
  disabled,
}) => {
  return (
    <div className="notification-item">
      <div className="notification-item__title">{title}</div>
      <div className="notification-item__button">
        <Switch
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          name={reportType}
          inputProps={{ "aria-label": "monthly report checkbox" }}
        />
      </div>
    </div>
  );
};

const NotificationSetting = () => {
  const { data: userInfo } = useMyInfo();
  const { isSubscribeMonthlyReport = false } = userInfo;
  const updateUser = useUpdateUser();
  const handleChange = (event) => {
    if (event.target.name === "monthlyReport") {
      const updateNotificationSettingParams: UpdateUserInfo = {
        isSubscribeMonthlyReport: event.target.checked,
      };
      updateUser.mutate(updateNotificationSettingParams);
    }
  };

  return (
    <>
      <AccountItemContainer>
        <AccountItemHeader title="Notification Setting" />
        <NotificationItem
          title="Monthly Report"
          reportType="monthlyReport"
          checked={isSubscribeMonthlyReport}
          handleChange={handleChange}
          disabled={updateUser.isLoading}
        />
      </AccountItemContainer>
    </>
  );
};

export default NotificationSetting;
