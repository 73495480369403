import { Dialog, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FunctionComponent } from "react";
import "./custom-modal.scss";

interface CustomModalProps {
  title: string;
  isOpen: boolean;
  children: any;
  handleCloseModal: () => void;
}

const CustomModal: FunctionComponent<CustomModalProps> = ({
  title = "",
  isOpen = false,
  children,
  handleCloseModal = () => {},
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
      className="custom-modal"
    >
      <div className="custom-modal__title">
        <div className="custom-modal__title-content">{title}</div>
        {handleCloseModal ? (
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            className="custom-modal__title-icon"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
      <div className="custom-modal__body">{children}</div>
    </Dialog>
  );
};

CustomModal.propTypes = {};

export default CustomModal;
