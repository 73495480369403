import React from "react";

interface SettingHeader {
  title?: string;
  description?: string;
}

const AccountItemHeader = ({ title, description }: SettingHeader) => {
  return (
    <div className="account-item-header">
      <div className="account-item-header-title">
        <div>{title}</div>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default AccountItemHeader;
