import { Link, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import CustomButton, {
  CustomButtonOutLine,
} from "../../../components/custom-button";
import { useChangePassword } from "../../../hooks/user.hooks.ts";
import {
  PASSWORD_VALIDATION_MESSAGE,
  validatePassword,
} from "../../../utils/password-validate";

const getTextFieldProps = (formik, name) => {
  return {
    name,
    className: "input-control",
    type: "password",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    variant: "filled",
    value: formik.values[name],
    onChange: formik.handleChange,
    error: formik.touched[name] && Boolean(formik.errors[name]),
    helperText: formik.touched[name] && formik.errors[name],
  };
};

const validate = (values) => {
  const errors = {};
  if (!values.currentPassword) {
    errors.currentPassword = "Required";
  }
  const validatedResult = validatePassword(values.newPassword);
  if (!validatedResult.verified) {
    for (const [rule, valid] of Object.entries(validatedResult.rules)) {
      if (!valid) {
        errors.newPassword = PASSWORD_VALIDATION_MESSAGE[rule];
        return errors;
      }
    }
  }
  if (values.confirmedNewPassword !== values.newPassword) {
    errors.confirmedNewPassword = "Confirm password does not match";
  }
  return errors;
};

export default function ChangePasswordSection() {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmedNewPassword: "",
    },
    validateOnChange: true,
    validate,
    onSubmit: (values) => {
      // eslint-disable-next-line no-use-before-define
      changePasswordRequest.mutate(values);
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    formik.resetForm();
  };

  const changePasswordRequest = useChangePassword({
    onSuccess: () => {
      handleCancel();
      enqueueSnackbar("Your password has been changed", {
        variant: "success",
      });
    },
    onError: (err) => {
      if (err.message === "Incorrect username or password.") {
        enqueueSnackbar("Incorrect current password", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      }
    },
  });

  return (
    <section className="change-password-section">
      {!open && (
        <Link
          onClick={handleOpen}
          component="button"
          className="change-password-button"
        >
          Change password
        </Link>
      )}
      {open && (
        <>
          <TextField
            label="Current Password"
            {...getTextFieldProps(formik, "currentPassword")}
          />
          <TextField
            label="New Password"
            {...getTextFieldProps(formik, "newPassword")}
          />
          <TextField
            label="Confirm New Password"
            {...getTextFieldProps(formik, "confirmedNewPassword")}
          />
          <div className="button-group">
            <CustomButtonOutLine
              size="small"
              label="Cancel"
              onClick={handleCancel}
            />
            <CustomButton
              size="small"
              isLoading={changePasswordRequest.isLoading}
              label="Save"
              onClick={formik.handleSubmit}
              disabled={changePasswordRequest.isLoading}
            />
          </div>
        </>
      )}
    </section>
  );
}
