import DateFnsUtils from "@date-io/date-fns";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Field, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { putMeApi } from "../../../api/users.ts";
import { useQueryInvalidationWithNotification } from "../../../hooks";
import { primaryColor } from "../../../scss/colors.scss";
import { monthDayFormater } from "../../../utils/calendar-util";
import { splitNameFromFullName } from "../../../utils/user-data-util";

const styles = () => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    padding: 50,
  },
  textField: {
    margin: 0,
    width: "100%",
    color: "black",
    opacity: 1,
    borderBottom: 0,
    "&:before": {
      borderBottom: 0,
    },
    "& input": {
      fontFamily: "ProximaNovaRegular",
      fontSize: 14,
      "-webkit-text-fill-color": "black",
      padding: 0,
    },
  },
  disabled: {
    color: "black",
    borderBottom: 0,
    "&:before": {
      borderBottom: 0,
    },
  },
  btnIcons: {
    marginLeft: 10,
  },
});

const FormikDatePicker = ({
  disabled,
  handleOnBlur,
  handleMouseOver,
  handleMouseOut,
  handleClick,
  form: { setFieldValue, handleSubmit },
  field: { value, name },
}) => {
  return (
    <KeyboardDatePicker
      variant="inline"
      autoOk
      disabled={disabled}
      onBlur={handleOnBlur}
      onClick={handleClick}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      format="dd/MM/yyyy"
      placeholder="--/--/----"
      onChange={(newDate) => {
        if (newDate && newDate.toString() !== "Invalid Date") {
          const formattedDate = monthDayFormater(newDate);
          setFieldValue(name, formattedDate);
          handleSubmit();
        }
      }}
      value={value ? new Date(value) : undefined}
      animateYearScrolling={false}
    />
  );
};

const CssSelect = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontSize: 14,
      fontFamily: "ProximaNovaRegular",
    },
    "& .MuiMenuItem-root": {
      fontSize: 14,
      fontFamily: "ProximaNovaRegular",
    },
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: primaryColor,
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  },
})(FormControl);

const INITIAL_USER_DATA = {
  firstName: "",
  lastName: "",
  gender: "",
  birthday: "",
  phoneNumber: "",
  postalAddress: "",
};

const EditableTextField = ({ classes, value, name }) => {
  const formRef = useRef();
  const invalidateQueries = useQueryInvalidationWithNotification();
  const [editFieldState, setEditFieldState] = useState({
    editMode: false,
    mouseOver: false,
    focused: false,
  });
  const [userData, setUserData] = useState(INITIAL_USER_DATA);
  const {
    firstName = "",
    lastName = "",
    gender = "",
    birthday = "",
    phoneNumber = "",
    postalAddress = "",
  } = userData;

  const handleMouseOver = () => {
    if (!editFieldState.mouseOver && !editFieldState.focused) {
      setEditFieldState({
        ...editFieldState,
        mouseOver: true,
        editMode: true,
      });
    }
  };

  const handleMouseOut = () => {
    if (editFieldState.mouseOver && !editFieldState.focused) {
      setEditFieldState({
        ...editFieldState,
        mouseOver: false,
        editMode: false,
      });
    }
  };

  const handleClick = () => {
    setEditFieldState({
      ...editFieldState,
      editMode: true,
      focused: true,
      mouseOver: false,
    });
  };

  const updateUser = useMutation(putMeApi, {
    onSuccess: () => {
      invalidateQueries("myInfo");
    },
  });

  const handleOnBlur = () => {
    setEditFieldState({ ...editFieldState, editMode: false, focused: false });
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    setUserData(value);
  }, [value]);
  const fullName = useMemo(() => {
    return `${firstName} ${lastName}`.trim();
  }, [firstName, lastName]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          fullName,
          phone: `${phoneNumber}`,
          birthday,
          gender,
          postalAddress: `${postalAddress}`,
        }}
        validate={(values) => {
          const errors = {};
          if (!values[name]) {
            errors[name] = "Required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          const [userFirstName, userLastName] = splitNameFromFullName(
            values.fullName
          );
          const params = {
            firstName: userFirstName,
            lastName: userLastName,
            gender: values.gender,
            birthday: values.birthday,
            phoneNumber: values.phone,
            postalAddress: values.postalAddress,
          };
          if (
            !(
              fullName === values.fullName &&
              gender === values.gender &&
              birthday === values.birthday &&
              phoneNumber === values.phone &&
              postalAddress === values.postalAddress
            )
          ) {
            updateUser.mutate(params);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            {name === "birthday" && (
              <Field
                component={FormikDatePicker}
                name="birthday"
                disabled={!editFieldState.editMode}
                handleClick={handleClick}
                handleOnBlur={handleOnBlur}
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
              />
            )}
            {name === "gender" && (
              <CssSelect>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  name="gender"
                  value={values?.gender}
                  disabled={!editFieldState.editMode}
                  onChange={handleChange("gender")}
                  onBlur={handleOnBlur}
                  onClick={handleClick}
                  onMouseEnter={handleMouseOver}
                  onMouseLeave={handleMouseOut}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </CssSelect>
            )}
            {name !== "birthday" && name !== "gender" && (
              <TextField
                name={name}
                value={values[name]}
                margin="normal"
                onChange={handleChange}
                disabled={!editFieldState.editMode}
                className={classes.textField}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
                onBlur={handleOnBlur}
                onClick={handleClick}
                helperText={errors[name] && touched[name] && errors[name]}
                error={errors[name] && touched[name]}
                InputProps={{
                  classes: {
                    disabled: classes.disabled,
                  },
                  endAdornment: editFieldState.mouseOver ? (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClick}>
                        <Edit />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    ""
                  ),
                }}
              />
            )}
          </form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

EditableTextField.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
};

export default withStyles(styles)(EditableTextField);
