import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import keyBy from "lodash/keyBy";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo, useState } from "react";
import { FASTLINK_PARAMS_GETTERS } from "../../../commons/enum";
import { CustomButtonOutLine } from "../../../components/custom-button";
import ExternalAccountsConnectUiDialog from "../../../components/ExternalAccountsConnectUIDialog/external-accounts-connect-ui-dialog";
import {
  useAccountListByInstitutionAccountId,
  useExternalAccountsConnectUI,
  useInstitutionAccounts,
  useInstitutionList,
  useUnlinkInstitutionAccount,
  useUnlinkAccount,
} from "../../../hooks/account.hooks";
import { useUpdateUserEmployment } from "../../../hooks/scenario.hooks";
import { by } from "../../../utils/object-util";
import AccountItemContainer from "./account-item-container";
import AccountItemHeader from "./account-item-header.tsx";

const InstitutionAccountSetting = ({
  disabled,
  logo,
  name,
  accountId,
  baseUrl,
  onClick,
  handleOpenDialog,
}) => {
  return (
    <div className="institution-account">
      <div className="institution-account-info">
        <div className="institution-account-info__logo">
          <img src={logo} alt={name} />
        </div>
        <div className="institution-account-info__info-container">
          <div className="institution-account-info__name">{`${name} | ${accountId}`}</div>
          <div className="institution-account-info__url">{baseUrl}</div>
        </div>
      </div>
      <div
        className="institution-account-button-container"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <CustomButtonOutLine
          label="Refresh Account"
          buttonType="secondary"
          disabled={disabled}
          onClick={onClick}
          size="small"
        />
        <CustomButtonOutLine
          label="Unlink Account"
          size="small"
          buttonType="secondary"
          onClick={(event) => {
            event.stopPropagation();
            handleOpenDialog({ id: accountId, isInstitutionAccount: true });
          }}
        />
      </div>
    </div>
  );
};
const BankAccountSetting = ({ logo, name, accountId, handleOpenDialog }) => {
  return (
    <div className="bank-account">
      <div className="bank-account-info">
        <div className="bank-account-info__logo">
          <img src={logo} alt={name} />
        </div>
        <div className="bank-account-info__name">{name}</div>
      </div>
      <div
        className="bank-account-button-container"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <CustomButtonOutLine
          label="Unlink Account"
          buttonType="secondary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            handleOpenDialog({ id: accountId });
          }}
        />
      </div>
    </div>
  );
};

const LinkedBankSetting = () => {
  const [isAtSelectBankStep, setIsAtSelectBankStep] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [accountValue, setAccountValue] = useState({
    id: "",
    isInstitutionAccount: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const unlinkInstitutionAccount = useUnlinkInstitutionAccount();
  const unlinkAccount = useUnlinkAccount();
  const updateUserEmployment = useUpdateUserEmployment();
  const { data: institutions } = useInstitutionList();
  const {
    isFetching: isFetchingInstitutionAccounts,
    data: institutionAccounts,
  } = useInstitutionAccounts();
  const {
    connect: connectExternalAccounts,
    uiShown: externalAccountsConnectUIShown,
    setUIShown: setExternalAccountsConnectUIShown,
    connectSuccessfullyRef,
    targetElementIdRef: externalAccountsConnectUIIdRef,
  } = useExternalAccountsConnectUI();

  const institutionsMap = useMemo(() => {
    return keyBy(institutions?.data || [], by("id"));
  }, [institutions]);

  const selectBankShown = isAtSelectBankStep && !isFetchingInstitutionAccounts;

  const closeRefreshAccountDataDialog = () => {
    setExternalAccountsConnectUIShown(false);
  };

  const handleOnSuccess = async () => {
    updateUserEmployment.mutateAsync();
    connectSuccessfullyRef.current = true;
  };

  const handleRefreshAccountData = (institutionAccountId) => {
    setIsAtSelectBankStep(false);
    const configParams = FASTLINK_PARAMS_GETTERS.REFRESH_ACCOUNT(
      institutionAccountId
    );
    connectExternalAccounts({ configParams, onSuccess: handleOnSuccess });
  };

  const hasRecentlyRefreshedInstitutionAccount = institutionAccounts?.some(
    (institutionAccount) => institutionAccount.isAllowToUpdate === false
  );

  const renderAccountList = useCallback(() => {
    return institutionAccounts?.map((institutionAccount) => {
      const institution = institutionsMap[institutionAccount.institutionId];
      return (
        <BankAccount
          key={institutionAccount.id}
          institutionAccount={institutionAccount}
          institution={institution}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionAccounts]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };
  const handleOpenDialog = (value) => {
    setAccountValue(value);
    setIsDialogOpen(true);
  };

  const handleUnlinkAccounts = () => {
    const handleUnlinkInstitutionAccount = (account) => {
      unlinkInstitutionAccount.mutate(account, {
        onSuccess: () => {
          enqueueSnackbar("Unlink institution account successfully", {
            variant: "success",
          });
          setAccountValue({ id: "", isInstitutionAccount: false });
          handleClose();
        },
      });
    };
    const handleUnlinkAccount = (account) => {
      unlinkAccount.mutate(account, {
        onSuccess: () => {
          enqueueSnackbar("Unlink account successfully", {
            variant: "success",
          });
          setAccountValue({ id: "", isInstitutionAccount: false });
          handleClose();
        },
      });
    };
    if (accountValue.isInstitutionAccount) {
      handleUnlinkInstitutionAccount(accountValue.id);
    } else {
      handleUnlinkAccount(accountValue.id);
    }
  };

  const BankAccount = ({ institutionAccount, institution }) => {
    const {
      data: { data: accountList } = {},
    } = useAccountListByInstitutionAccountId(institutionAccount.id);
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <InstitutionAccountSetting
            key={institutionAccount.id}
            disabled={!institutionAccount.isAllowToUpdate}
            onClick={(event) => {
              event.stopPropagation();
              handleRefreshAccountData(institutionAccount.id);
            }}
            name={institution?.name}
            accountId={institutionAccount.id}
            logo={institution?.favicon}
            baseUrl={institution?.baseUrl}
            handleOpenDialog={handleOpenDialog}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className="bank-account-list">
            {accountList?.length > 0 &&
              accountList.map((account) => (
                <BankAccountSetting
                  key={account.id}
                  accountId={account.id}
                  name={account?.name}
                  logo={institution?.favicon}
                  handleOpenDialog={handleOpenDialog}
                />
              ))}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <ExternalAccountsConnectUiDialog
        className="refresh-account-dialog"
        id={externalAccountsConnectUIIdRef.current}
        open={externalAccountsConnectUIShown}
        isLoading={!selectBankShown}
      >
        <IconButton
          className="refresh-account-dialog__close-button"
          onClick={closeRefreshAccountDataDialog}
        >
          <CloseIcon />
        </IconButton>
        {selectBankShown && (
          <div className="refresh-account-dialog__main">
            <div className="refresh-account-dialog__head">
              <p>Please select the bank to refresh</p>
              {hasRecentlyRefreshedInstitutionAccount && (
                <small>
                  User might not be able to refresh a recently refreshed/updated
                  bank ℹ️
                </small>
              )}
            </div>
          </div>
        )}
      </ExternalAccountsConnectUiDialog>
      <AccountItemContainer>
        <AccountItemHeader title="Linked Account Connections" />
        <Dialog
          open={isDialogOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          className="unlink-account-modal"
        >
          <div className="unlink-account-modal__container">
            <div className="unlink-account-modal__title">
              Are you sure you want to unlink this account?
            </div>
            <div className="unlink-account-modal__description">
              Your financial data will be reloaded, and some below data will be
              incorrect.
              <p> - Property </p>
              <p> - Scenario </p>
              <p> - Submitted Loan Application </p>
            </div>
            <div className="unlink-account-modal__button-group">
              <CustomButtonOutLine
                label="Unlink"
                size="small"
                onClick={handleUnlinkAccounts}
                isLoading={
                  unlinkInstitutionAccount.isLoading || unlinkAccount.isLoading
                }
                disabled={
                  unlinkInstitutionAccount.isLoading || unlinkAccount.isLoading
                }
              />
            </div>
          </div>
        </Dialog>
        {renderAccountList()}
      </AccountItemContainer>
    </>
  );
};

export default LinkedBankSetting;
