/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import DataSharing from "../../../features/account/data-sharing";
import NotificationSetting from "../../../features/account/notification-setting";
import ContactInfoComponent from "../../../features/account/personal-account/contact-info-component.tsx";
import DeleteAccountComponent from "../../../features/account/personal-account/delete-account-component";
import LinkedBankSetting from "../../../features/account/personal-account/linked-bank-setting";
import ProfileComponent from "../../../features/account/personal-account/profile-component";
import { useMyInfo } from "../../../hooks/user.hooks.ts";

const PersonalAccount = () => {
  const { data: userInfo } = useMyInfo();

  return (
    <div className="account-container">
      <ProfileComponent userData={userInfo} />
      <ContactInfoComponent userData={userInfo} />
      <LinkedBankSetting />
      <DataSharing />
      <NotificationSetting />
      <DeleteAccountComponent />
    </div>
  );
};

export default PersonalAccount;
