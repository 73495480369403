/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { withStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { useState } from "react";
import CustomButton, {
  CustomButtonOutLine,
} from "../../../components/custom-button";
import CustomModal from "../../../components/CustomModal";
import { useStaffList } from "../../../hooks/broker.hooks";
import { useUpdateUserAssociate } from "../../../hooks/user.hooks.ts";
import { primaryColor } from "../../../scss/colors.scss";
import AccountItemContainer from "../personal-account/account-item-container";
import AccountItemHeader from "../personal-account/account-item-header.tsx";
import "./sharing-data-setting.scss";

const PurpleSwitch = withStyles({
  switchBase: {
    color: primaryColor,
    "&$checked": {
      color: primaryColor,
    },
    "&$checked + $track": {
      backgroundColor: primaryColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

const DataSharingItem = ({
  checked,
  reportType,
  handleChange,
  title,
  disabled,
}) => {
  return (
    <div className="sharing-data-item">
      <div className="sharing-data-item__title">{title}</div>
      <div className="sharing-data-item__button">
        <PurpleSwitch
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          name={reportType}
          inputProps={{ "aria-label": "monthly report checkbox" }}
        />
      </div>
    </div>
  );
};

const DataSharing = () => {
  const { data: staffList } = useStaffList();
  const [selectedStaff, setSelectedStaff] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const updateUserAssociate = useUpdateUserAssociate();
  const handleOpenDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleUpdateSharingData = (params) => {
    updateUserAssociate.mutate(params, {
      onSuccess: () => {
        setIsDialogOpen(false);
      },
    });
  };

  return (
    <>
      <AccountItemContainer>
        <AccountItemHeader title="Data Sharing" />
        {staffList?.map((staff) => (
          <DataSharingItem
            key={staff.id}
            title={staff.name}
            reportType="monthlyReport"
            checked={staff.status}
            handleChange={() => {
              setSelectedStaff(staff);
              if (staff.status) {
                handleOpenDialog();
              } else {
                handleUpdateSharingData({
                  id: staff.id,
                  params: { status: !staff.status },
                });
              }
            }}
          />
        ))}
        <CustomModal
          handleCloseModal={handleOpenDialog}
          isOpen={isDialogOpen}
          title="Sharing Data"
        >
          <div className="sharing-data-modal">
            <div className="sharing-data-modal__content">
              Turn off sharing data with {selectedStaff?.name}?
            </div>
            <div className="sharing-data-modal__action-buttons">
              <CustomButtonOutLine label="Cancel" onClick={handleOpenDialog} />
              <CustomButton
                label="Confirm"
                onClick={() =>
                  handleUpdateSharingData({
                    id: selectedStaff?.id,
                    params: { status: !selectedStaff?.status },
                  })
                }
                isLoading={updateUserAssociate.isLoading}
              />
            </div>
          </div>
        </CustomModal>
      </AccountItemContainer>
    </>
  );
};

export default DataSharing;
